.form-group {
    margin-bottom: 24px;
    .field-label {
      display: block;
      font-size: 14px;
      font-weight: 600;
      color: #4d4d4d;
      margin-bottom: 10px;
    }
    input,
    textarea {
      resize: none;
      border-radius: 4px;
      padding: 12px 14px;
      border: 1px solid #d9d9d9;
      width: 100%;
      color: #1a181e;
      font-size: 14px;
      &::placeholder {
        color: #8c8c8c;
      }
      &:focus {
        outline: none;
        border-color: #f27264;
      }
    }

  }
  
