.internal-score {
    overflow: hidden;
    height: calc(100vh - 210px);
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(15, 14, 23, 0.05);
    border-radius: 4px;
    overflow: auto;
    width: calc(100vw - 272px);


    .internal-score-box {
        border: 1px solid #F54B64;
        padding: 24px;
        border-radius: 4px;
        min-width: 261px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
    }

    .loan-box {
      border: 1px solid #F54B64;
      padding: 8px 16px;
      border-radius: 4px;
      min-width: 261px;
    }

    .table {
        font-size: 12px;
        min-width: 500px;
        border: 1px solid #A7A9BE;
        border-radius: 6px;

        header {
          border-bottom: 1px solid #A7A9BE;
        }

        header, .row {
            display: flex; 
            padding: 12px;
          }
          
          .col {
            flex: 1;  
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
          }
    }
}

.scoring-criteria {
  .box {
    border: 1px solid #A7A9BE;
    border-radius: 8px 8px 0 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    .column {
        &:not(:last-child) {
        border-right: 1px solid #A7A9BE;
      }
     } 

     
  }

  .manual-verification-container {
    border: 1px solid #F54B64;
  }

  .total-score {
    border: 1px solid #A7A9BE;
    border-top: 0;
    border-radius: 0 0 8px 8px ;
    div {
      width: 12.5%;
    }
   }
}

.manual-verify {
  border: 1px solid #A7A9BE;
  margin-top: 24px;
  border-radius: 8px;
  height: 50px;
}

.scoring-criteria-btn {
  padding: 10px 20px;
  background: #242A38;
  border-radius: 5px;
  color: #fff;
  outline: none;
  border: 0;
  cursor: pointer;
  height: 32px;
  margin-top: 14px;
  margin-left: 8px;
  margin-right: 16px;

  &:hover {
      background: #161a23;
  }
}