.internal-score-wrap {
  overflow: hidden;
  height: calc(100vh - 150px);
  background: #fff;
  overflow: auto;
  width: calc(100vw - 240px);

  .box-id {
    color: #e8e8eb;
    font-weight: 600;
    font-size: 24px;
  }

  .header-text {
    margin: 0;
    letter-spacing: 0.01em;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 16px;
  }

  .criteria-box {
    height: 70px;
    width: calc(100vw - 320px);
    border: 1px solid #a7a9be;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr 0.4fr;
  }
}

.edit-criteria-modal {
  &.loan-criteria {
   width: 60%;

   .edit-criteria-box {
    display: grid;
    grid-template-columns: 0.6fr 1fr 0.8fr 1fr;
  }
  }

  .edit-criteria-box {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  .disabled-input-ui {
    background: rgba(232, 232, 235, 0.6);
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 6px;
    margin-right: 12px;
    font-size: 14px;
  }

  .edit-input {
    font-size: 14px;
    border: 1px solid #A7A9BE;
    border-radius: 5px;
    padding: 2px 20px;

    &.multi-input {
      width: 25%;
      height: 27px;
    }
  }

  .number-input {
    font-size: 14px;
    border: 1px solid #A7A9BE;
    border-radius: 5px;
    padding: 2px 20px;
    height: 25px;
    width: 40px;
  }

  .range-input {
    font-size: 14px;
    border: 1px solid #F54B64;
    border-radius: 5px;
    padding: 2px 20px;
    height: 25px;
    width: 65px;
  }

  .delete-btn {
    border-radius: 6px;
    border: 1.2px solid #242A38;
    cursor: pointer;
    width: 35px;
  }

  .otp-text {
    color: #A7A9BE;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }

  .edit-criteria-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .discard-btn {
    padding: 10px 24px;
  }
}
