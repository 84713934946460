@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap");

body {
  font-family: "Raleway";
  letter-spacing: 0.04rem;
  margin: 0;
  padding: 0;
  color: #0F0E17;
  font-weight: 500;
}

input {
  &:focus {
    outline: none;
  }
}


@mixin display-flex($align: center, $justify: center) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin font-size($size, $line-height) {
  font-size: #{$size}px;
  line-height: #{$line-height}px;
}

@mixin font-size-weight($size, $line-height, $weight) {
  font-size: #{$size}px;
  line-height: #{$line-height}px;
  font-weight: $weight;
}

@mixin spacing($space) {
  .pl#{$space} {
    padding-left: #{$space}px;
  }
  .ml#{$space} {
    margin-left: #{$space}px;
  }
  .pr#{$space} {
    padding-right: #{$space}px;
  }
  .mr#{$space} {
    margin-right: #{$space}px;
  }
  .pt#{$space} {
    padding-top: #{$space}px;
  }
  .mt#{$space} {
    margin-top: #{$space}px;
  }
  .pb#{$space} {
    padding-bottom: #{$space}px;
  }
  .mb#{$space} {
    margin-bottom: #{$space}px;
  }
  .my#{$space} {
    margin-top: #{$space}px;
    margin-bottom: #{$space}px;
  }
  .mx#{$space} {
    margin-left: #{$space}px;
    margin-right: #{$space}px;
  }
  .py#{$space} {
    padding-top: #{$space}px;
    padding-bottom: #{$space}px;
  }
  .px#{$space} {
    padding-left: #{$space}px;
    padding-right: #{$space}px;
  }
  .p#{$space} {
    padding: #{$space}px;
  }
  .m#{$space} {
    margin: #{$space}px;
  }
}

@mixin content-size($width, $height) {
  width: #{$width}px;
  height: #{$height}px;
}

@mixin box-style {
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(26, 24, 30, 0.04);
  background-color: #ffffff;
}

$black-1: #2e3236;
$black-2: #1a181e;
$black-3: #1e2640;
$black-4: #4d4d4d;
$black-5: #312f34;

$gray-1: #808080;
$gray-2: #8c8c8c;
$gray-3: #cccccc;
$gray-4: #666666;
$gray-5: #b3b3b3;
$gray-6: #8c8c8c;

$white: #ffffff;
$white-1: #ffffff;
$white-2: #f7f7f7;
$white-3: #d9d9d9;
$white-4: #e8e8e8;
$white-5: #e6e6e6;
$white-6: #ededed;
$white-7: #f5f5f5;
$white-8: #f2f2f2;
$white-9: #d8d8d8;

$red-1: #ff5f4a;
$red-2: #e50b20;
$red-3: #f44336;
$red-4: #cc0a1d;
$red-5: #c11f2c;

$blue-1: #2f80ed;
$blue-2: #2f80ed;
$blue-3: #5297f2;
$blue-4: #f9f5fe;

$orange-1: #ea6f57;
$orange-2: #ffb74f;
$orange-3: #ed7c3b;
$orange-4: #ffc774;
$orange-5: #f78d5f;

$green-1: #17b31b;
$green-2: #87b12a;
$green-3: #008a43;

$primary-color: $blue-1;
$body-color: $black-2;

$sm-breakpoint: (
  min-width: 576px,
);
$md-breakpoint: (
  min-width: 768px,
);
$lg-breakpoint: (
  min-width: 992px,
);
$xl-breakpoint: (
  min-width: 1200px,
);

$sm-container-width: 540px;
$md-container-width: 720px;
$lg-container-width: 960px;
$xl-container-width: 1140px;

$sidebar-width: 200px;
$toolbar-height: 40px;
$notificationbar-height: 42px;

$ylwColors:
    "4E596F" #4E596F,
    'A7A9BE' #A7A9BE,
;


.d-flex-c-s {
  @include display-flex(center, space-between);
}
.d-flex-c-f {
  @include display-flex(center, flex-start);
}
.d-flex-f-f {
  @include display-flex(flex-start, flex-start);
}
.d-flex-c-e {
  @include display-flex(center, flex-end);
}
.d-flex-c-c {
  @include display-flex;
}

// .d-flex {
//   display: flex;
// }

.flex-col {
  flex-direction: column;
}




// spacing mixins ------------------------------------------------------------

$space: 80;
$font-size: 40;
$font-weight: 900;


// PADDING & MARGINS 0, 2, 4 ... 50px
@while $space >= 0 {
  @include spacing($space);
  $space: $space - 2;
}
.ml-auto,
.mx-auto {
  margin-left: auto;
}
.mr-auto,
.mx-auto {
  margin-right: auto;
}
.mt-auto,
.my-auto {
  margin-top: auto;
}
.mb-auto,
.my-auto {
  margin-bottom: auto;
}

// Typography ---------------------------------------------------------------


// FONT SIZE 12, 14, 16 ... 40px
@while $font-size >= 6 {
  .f#{$font-size} {
    font-size: #{$font-size}px !important;
  }
  $font-size: $font-size - 2;
}


 // FONT Weight 100, 200, 300 ... 900
@while $font-weight >= 100 {
  .fw#{$font-weight} {
    font-weight: #{$font-weight} !important;
  }
  $font-weight: $font-weight - 100;
} 

// FONT COLORS #E9F2FF, #CEE1FF ... #010101
@each $i in $ylwColors {
  .fc#{nth($i,1)} {
       color: nth($i,2) !important;
  }
}

.cur-p {
  cursor: pointer;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-primary {
  // background: linear-gradient(225deg, #F78361 0%, #F54B64 100%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  color: #F54B64;
}

.pagination-select {
  @include font-size(12, 22);
  color: $black-4;
  min-width: 125px;
  height: 32px;
  padding-left: 8px;
  border-radius: 5px;
  border: 1px solid #242A38;
  background-position: 95% center !important;
  background-size: 10px !important;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 500;
}


@mixin modal-size($size) {
  width: #{$size}px;
  max-width: #{$size}px;
}
.dialog-modal {
  .dialog-backdrop {
    background-color: rgb(0, 0, 0) !important;
    opacity: 0.6 !important;
  }
  .dialog-paper {
    @include box-style;
    &.xs {
      @include modal-size(360);
    }
    &.sm {
      @include modal-size(480);
    }
    &.md {
      @include modal-size(640);
    }
    &.lg {
      @include modal-size(858);
    }
    &.xl {
      width: 100vw;
      max-width: 100vw;
    }
  }
  .dialog-content.MuiDialogContent-root {
    color: $body-color;
    padding: 24px;
  }
}

.relative {
  position: relative;
}

.ylw-secondary-btn {
  background-color: #242A38;
  color: #fff;
  max-width: 229px;
  outline: none;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 12px;
  padding: 10px 8px;
  font-weight: 500;
}

.ylw-primary-btn {
  cursor: pointer;
  transition: 0.15s ease-in-out;
  background: linear-gradient(225deg, #F78361 0%, #F54B64 100%);
  text-align: center;
  outline: none;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  color: #fff;

  &.disabled,
  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
  }
}

.ylw-primary-outline-btn {
  cursor: pointer;
  transition: 0.15s ease-in-out;
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  color: #F54B64;
  border: 2px solid #F78361;


  &.disabled,
  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
  }
}