.account-details-wrap {
  .account-details-body {
    overflow: hidden;
    height: calc(100vh - 210px);
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(15, 14, 23, 0.05);
    border-radius: 4px;
    overflow: auto;
    width: calc(100vw - 290px);

    .profile {
      background: rgba(232, 232, 235, 0.6);
      border-radius: 10px;
      width: 466px;
      margin-right: 56px;
      padding: 16px 0;
    }
  }

  .user-image {
    border-radius: 10px;
    width: 120px;
    height: 120px;
    font-size: 40px;
    margin-left: 16px;
    text-transform: capitalize;
  }

  .user-details {
    min-width: 400px;
  }

  .blocked-reason-input {
    width: 300px;
    height: 100%;
    border: none;
    border-bottom: 1px solid #0f0e17;
    outline: none;
    resize: none;
    padding: 6px 0;
    font-size: 18px;
    font-weight: normal;
    color: #4e596f;
    background-color: #ffffff;
    border-radius: 0;
  }

  .payment-methods {
    border: 0.6px solid #a7a9be;
    border-radius: 10px;
    min-width: 416px;
  }

  .onboarding-status-stepper {
    height: 200px;
    border: 0.6px solid #A7A9BE;
    border-radius: 10px;
  }
  .MuiStepper-root {
    background: transparent !important;
  }
}





.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  font-family: "Raleway";
  letter-spacing: 0.04rem;
  color: #0F0E17;
  font-weight: 500;
}

.name_user-details {
  border: 0;
  background-color: transparent;
  border-bottom: 0.6px solid #A7A9BE;
  width: 50%;
}

.name_user-email {
  border: 0;
  background-color: transparent;
  border-bottom: 0.6px solid #A7A9BE;
  width: 50%;
}

.submit-icon {
  border: 0;
  margin: 0;
  padding: 0;
  margin-top: 4px;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.camera-icon {
  position: absolute;
  top: 80px;
  left: 95px;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.camera-icon-container {
  border: 0;
  margin: 0;
  padding: 0;
}