.documents-wrap {
    overflow: hidden;
    height: calc(100vh - 210px);
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(15, 14, 23, 0.05);
    border-radius: 4px;
    overflow: auto;
    width: calc(100vw - 270px);

    .kyc-info {
        border-radius: 5px;
        border: 1px solid #F54B64;
        min-width: 340px;
    }

    .pan-card {
        border-radius: 5px;
        border: 1px solid #F54B64;
        max-width: 355px;
    }

    .adhaar-card {
        border-radius: 5px;
        border: 1px solid #F54B64;
        max-width: 355px;
        min-height: 253px;
    }

    .phone-numbers {
        border-radius: 5px;
        border: 1px solid #A7A9BE;
        min-width: 330px;
        min-height: 253px;
    }

    .adhaar-address-info {
        background-color: #242A38;
        color: #fff;
        max-width: 229px;
        height: 35px;
        outline: none;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
    }

    .title-desc {
        min-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
    }
}

.address-info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}