.custom-tabs-buttons-ui {
  .Mui-selected {
    .MuiTab-wrapper {
      font-size: 12px;
      background: linear-gradient(225deg, #f78361 0%, #f54b64 100%);
      border-radius: 5px;
      color: #fff;
      font-family: "Raleway";
    }
  }
  .MuiTab-wrapper {
    font-size: 12px;
    background-color: #fff;
    border-radius: 5px;
    padding: 6px;
    text-transform: capitalize;
    font-family: "Raleway";
  }
  .MuiTabs-indicator {
    display: none;
  }

  .MuiButtonBase-root {
    padding-left: 0 !important;
  }

  .MuiTabs-root {
    margin-bottom: 6px;
  }
  .MuiTab-root {
    min-width: 151px;
  }
}

.custom-tabs-indicator-ui {
  .Mui-selected {
    .MuiTab-wrapper {
      font-size: 14px;
      background: #fff;
      border-radius: 5px;
      font-weight: 700;
      color: #f54b64;
      font-family: "Raleway";
    }
  }
  .MuiTab-wrapper {
    font-size: 14px;
    background-color: #fff;
    border-radius: 5px;
    padding: 6px;
    font-family: "Raleway";
  }
  .MuiTabs-indicator {
    display: flex;
  }

  .MuiButtonBase-root {
    border-bottom: 2px solid #e8e8eb;
    margin-right: 24px;
  }

  .MuiTabs-root {
    margin-bottom: 6px;
  }
}