
.transactions-wrap {
  .react-transactions-table {
    overflow: hidden;
    height: calc(100vh - 210px);
    width: calc(100vw - 240px);
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(15, 14, 23, 0.05);
    border-radius: 5px;
    top: 0;

    &:hover {
      overflow: auto;
    }

    .table {
      background-color: #fff;
      font-size: 14px;

      .tr > div:nth-last-child(5) {
        grid-column: span 2;
      }
      .tr > div:nth-last-child(4) {
        grid-column: span 2;
      }
      .tr > div:nth-last-child(3) {
        grid-column: span 2;
      }
      .tr > div:nth-last-child(2) {
        grid-column: span 2;
      }
      .header {
        padding: 0 24px;
        background: #e8e8eb;
        position: sticky;
        top: 0;
        z-index: 100;

        .tr {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
          border-bottom: 1px solid #e4e7ec;

          .th {
            padding-bottom: 16px;
            padding-top: 16px;
          }

          .header-title {
            font-weight: bold;
            font-size: 10px;
            letter-spacing: 0.01em;
            line-height: 18px;
            text-transform: uppercase;
            color: #4e596f;
            padding-left: 0;
          }
        }
      }
      .body {
        padding: 0 24px;
        .tr.isActive {
          background-color: #e9f2ff;
        }

        .tr {
          padding: 6px 0;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
          align-items: center;
          width: 100%;
          &:hover {
            cursor: pointer;
            background: #f1f1f2;
          }
        }
      }
    }
  }

  .header-text {
    margin: 0;
    letter-spacing: 0.01em;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 16px;
  }

  .tb-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .MuiAvatar-root {
      width: 30px;
      height: 30px;
      font-size: 12px;
      text-transform: capitalize;
    }

    p {
      background: linear-gradient(225deg, #f78361 0%, #f54b64 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
      margin-left: 10px;
      margin-right: 10px;

      &:hover {
        color: #fff;
      }
    }
  }

  .tb-text {
    letter-spacing: 0.01em;
    color: #0f0e17;
    font-weight: 600;
  }

  .header-bar {
    height: 80px;
    background-color: #fff;
    display: block;
  }


}
