.repayments-wrap {
  overflow: hidden;
  height: calc(100vh - 240px);
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(15, 14, 23, 0.05);
  border-radius: 4px;
  overflow: auto;
  width: calc(100vw - 270px);

  .body {
    .first-installment {
      padding: 26px 20px;
      border-radius: 5px;
      border: 1px solid #f54b64;

      p {
          margin: 0;
      }
    }

    .second-installment {
      padding: 26px 20px;
      border-radius: 5px;
      border: 1px solid #A7A9BE;

      svg {
          circle {
              fill: #F55459;
          }
      }
    }
    .third-installment {
      padding: 26px 20px;
      border-radius: 5px;
      border: 1px solid #A7A9BE;

      svg {
        circle {
            fill: #FFC227;
        }
    }
    }

    .installment-details {
        width: 160px;
    }
  }
  .view-transactions {
    background-color: #242A38;
    color: #fff;
    max-width: 229px;
    height: 35px;
    outline: none;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    margin-left: 24px;
  }
}
