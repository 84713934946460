@import '../../styles/app';
.switch-label.MuiFormControlLabel-root {
    margin: 0;
    .MuiButtonBase-root {
      padding-left: 2px !important;
  }
    .MuiFormControlLabel-label {
      @include font-size-weight(12, 14, 500);
      font-size: 12px;
      line-height: 14px;
      margin-right: 8px;
      color: #4d4d4d;
      opacity: 1;
    }
  }
    .Mui-disabled {
      opacity: 0.7;
    }
  .switch {
    &.MuiSwitch-root {
      @include content-size(36, 18);
      margin: 0 12px 0 0;
      padding: 0;
      .MuiSwitch-input {
        visibility: hidden;
        @include content-size(0, 0);
        left: 0;
      }
      .MuiSwitch-track {
        opacity: 1;
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 10px;
        background-color: $white-9;
      }
      &.MuiSwitch-light-off-theme {
        .MuiSwitch-track {
          border: 1px solid $white-9;
          background-color: $white-9;
        }
      }
      .MuiSwitch-thumb {
        @include content-size(14, 14);
        box-shadow: none;
      }
      .MuiSwitch-switchBase {
        padding: 2px;
        &:hover {
          background-color: transparent;
        }
        &.Mui-checked {
          transform: translateX(18px);
          color: #fff;
        }
        &.Mui-checked + .MuiSwitch-track {
          background: #33BF9E;
          opacity: 1;
          border: none;
        }
      }
    }
  }
  