.btn-primary-4 {
  cursor: pointer;
  display: inline-block;
  transition: 0.15s ease-in-out;
  background-color: #f27264;
  text-align: center;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
  color: #fff;
  width: 384px;
  padding: 4px;
  margin-top: 8px;

  &.disabled,
  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
  }
}

.auth-container,
.forgot-pass-container {
  min-height: 75vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 100%;
  padding: 40px;
  margin: auto;
  .MuiOutlinedInput-input {
    padding: 12.5px 14px;
  }
  .auth-form-wrap {
    max-width: 352px;
    margin: 0 auto;
    .MuiFormControl-root {
      label + .MuiInput-formControl {
        margin-top: 20px;
      }
    }
  }
  .auth-logo-wrap {
    padding-top: 32px;
    margin-bottom: 112px;
  }
  footer {
    color: #8c8b8f;
    margin-top: auto;
    padding-bottom: 32px;
  }
}

.auth-container {
  .auth-form-wrap {
    min-width: 290px;
    min-height: 100%;
    position: relative;
    top: -8px;
    display: flex;
    flex-direction: column;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #707e88;
  margin-right: 9%;
  margin-left: 12%;
}

.navbar__brand {
  width: 80px;
}

.illustration-container {
  display: flex;
  justify-content: center;
  img {
    width: 90%;
  }
}

.auth-layout {
  max-width: 1168px;
  justify-content: center;
  text-align: left;
  margin: auto;
}
.row {
  display: flex;
  flex: 0 1 auto;
}

.flex-half {
  flex-basis: 50%;
  max-width: 50%;
}

.section-text-1 {
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.section-text-6 {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 32px 0;
  line-height: 24px;
}
