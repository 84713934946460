@import "../../styles/app.scss";

.dash-layout {
  display: flex;
  height: 100%;
  overflow: hidden;

  .dash-layout-content {
    flex-grow: 1;
    height: 100%;
    min-height: calc(100vh - 70px);
    position: relative;
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    background: #E8E8EB;
  }
  .main-header {
    &.MuiPaper-root {
      border-bottom: 1px solid #0000001f;
      background: #0f0e17;
      color: $body-color;
      box-shadow: none;
      @media (min-width: 600px) {
        .header-menu-icon {
          display: none;
        }
      }
    }
  }
}

.dash-layout-wrapper {
  margin-top: $toolbar-height;
}

.menu-list-wrap {
  padding: 0 20px;
  ul {
    padding: 0;
    li {
      list-style: none;
      text-decoration: none;

      a {
        text-decoration: none;
        color: #242a38;

        &.active {
          color: #fff;
          span {
            background: linear-gradient(225deg, #f78361 0%, #f54b64 100%);
            border-radius: 5px;
            height: 40px;
          }
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}

.menu-sidebar {
  &.MuiPaper-root {
    width: $sidebar-width;
    background-color: $white-1;
    color: #fff;
  }
}

.menu-sidebar-wrap {
  border-right: 1px solid #eee;
  padding-top: 58px;
  @media (min-width: 600px) {
    width: $sidebar-width;
    flex-shrink: 0;
  }
  .MuiDrawer-paper {
    z-index: 0;
  }
}

.nav-span-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding-left: 20px;
  font-size: 14px;

  svg {
    margin-right: 8px;
    path {
      stroke: black;
    }
  }
}

.menu-header-text {
  color: #a7a9be;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.2em;
  border-bottom: 0.6px solid #a7a9be;
  padding-bottom: 24px;
  padding-top: 6px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
