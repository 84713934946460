@import url(../../styles/app.scss);
.react-table {
    overflow: hidden;
    height: calc(100vh - 240px);
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(15, 14, 23, 0.05);
    border-radius: 5px;
    top: 0;
  
    &:hover {
      overflow: auto;
    }
  
    .table {
      background-color: #fff;
      font-size: 14px;


      .tr > div:nth-last-child(8) {
        grid-column: span 2;
      }
      .tr > div:nth-last-child(6) {
        grid-column: span 2;
      }
      .header {
        padding: 0 24px;
        background: #E8E8EB;
        position: sticky;
        top: 0;
        z-index: 100;
      
  
        .tr {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
          border-bottom: 1px solid #E4E7EC;
  
  
          .th {
            padding-bottom: 16px;
            padding-top: 16px;
           
          }
  
  
          .header-title {
            font-weight: bold;
            font-size: 10px;
            letter-spacing: 0.01em;
            line-height: 18px;
            text-transform: uppercase;
            color: #4E596F;
            padding-left: 0;
          }
        }
      }
        .body {
          padding: 0 24px;
        .tr.isActive {
            background-color: #E9F2FF;
        }
  
        .tr {
          padding: 12px 0;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
          align-items: center;
          width: 100%;
          &:hover {
            cursor: pointer;
            background: #f1f1f2;
          }
        }
      }
    }
  }

  .header-text {
    margin: 0;
    letter-spacing: 0.01em;
    font-weight: 600;
    font-size: 20px;  
    margin-bottom: 20px;  
    margin-top: 16px;
  }

  .tb-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .MuiAvatar-root {
      width: 30px;
      height: 30px;
      font-size: 12px;
      text-transform: capitalize;
    }

    p {
      background: linear-gradient(225deg, #F78361 0%, #F54B64 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
      font-weight: 600;
       margin-left: 10px;
       margin-right: 10px;

       &:hover {
        color: #fff;
       }
    }
  }

  .tb-text {
    letter-spacing: 0.01em;
    color: #0F0E17;
    font-weight: 600;
  }


  .header-bar {
    height: 55px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
  }

  .input-bar {
    width: 351px;
    border: 1px solid #242a38;
    border-radius: 5px;
    height: 30px;
    padding: 0 12px;
  }

  .search-icon-container {
    background: #242a38;
    width: 30px;
    height: 30px;
    position: relative;
    left: 346px;
    top: -31px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }


  .transactions-icon {
    left: 366px !important;
  }

  .filter-btn {
    padding: 10px 20px;
    background: #242A38;
    border-radius: 5px;
    color: #fff;
    outline: none;
    border: 0;
    cursor: pointer;
    height: 32px;
    margin-top: 14px;
    margin-left: 8px;
    margin-right: 16px;

    &:hover {
        background: #161a23;
    }
  }