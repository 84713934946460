.pagination-server-side {
    bottom: -6px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 50px;
    padding: 0 24px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 93%;
    background: transparent;
    display: flex;
    list-style-type: none;

    .pagination-btn {
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(19, 21, 21, 0.1);
        border-radius: 5px;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  
    .pagination-item {
      padding: 0 12px;
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      line-height: 1.43;
      font-size: 13px;
      min-width: 32px;
  
      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
  
      &.selected {
        background-color: rgba(0, 0, 0, 0.08);
      }
  
  
      &.disabled {
        pointer-events: none;
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }


  