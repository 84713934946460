.score-input {
  border: 1px solid #a7a9be;
  border-radius: 5px;
  width: 110px;
  padding: 10px;
  outline: none;

  &::placeholder {
      font-family: 'Raleway';
      font-weight: 400;
      font-size: 12px;
  }
}

.green-dot {
    background: #33BF9E;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.yellow-dot {
    width: 10px;
    height: 10px;
    background: #FFC227;
    border-radius: 50%;
}

.red-dot {
    width: 10px;
    height: 10px;
    background: #F55459;
    border-radius: 50%;
}

.react-datepicker-wrapper {
    .react-datepicker__input-container {
        input {
            border: 1px solid #a7a9be;
            border-radius: 5px;
            width: 110px;
            padding: 10px;
            outline: none;
            
            &:first-child {
                margin-left: 12px;
            }
          
            &::placeholder {
                font-family: 'Raleway';
                font-weight: 400;
                font-size: 12px;
            }
        }
    }
}

.react-datepicker__header {
    background: #fff !important;
}

.react-datepicker {
    border: 1px solid #A7A9BE !important;
    border-radius: 5px !important;
    box-shadow: 0px 0px 20px rgba(15, 14, 23, 0.05) !important;
}

.react-datepicker__day--in-selecting-range {
    background: linear-gradient(225deg, #F78361 0%, #F54B64 100%) !important;

    &:hover {
        color: #fff;
    }
}


.react-datepicker__day--keyboard-selected {
    background: linear-gradient(225deg, #F78361 0%, #F54B64 100%) !important;
}

.react-datepicker__day--selected {
    background: linear-gradient(225deg, #F78361 0%, #F54B64 100%) !important;
}

.reset-btn {
    padding: 10px 20px;
    background: #242A38;
    border-radius: 5px;
    color: #fff;
    outline: none;
    border: 0;
    cursor: pointer;

    &:hover {
        background: #161a23;
    }
}

.save-btn {
    padding: 10px 20px;
    background: linear-gradient(225deg, #F78361 0%, #F54B64 100%);
    border-radius: 5px;
    color: #fff;
    outline: none;
    border: 0;
    cursor: pointer;

    &:hover {
        background: #F54B64;
    }
}

.cross-icon {
    position: absolute;
    right: 24px;
    cursor: pointer;
}