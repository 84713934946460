.creditBureau-wrap {
  overflow: hidden;
  height: calc(100vh - 210px);
  background: transparent;
  overflow: auto;
  width: calc(100vw - 240px);

  .dpd-details {
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(15, 14, 23, 0.05);
    height: 28vh;
    grid-gap: 1rem;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;

    .max-dpd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      border: 1px solid #f54b64;
      padding-left: 16px;
      margin-right: 24px;
      font-size: 14px;
    }
    .ylw-indicator {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 5px;
      border: 1px solid #f54b64;
      padding: 10px 8px;
      margin-right: 24px;
      font-size: 14px;
    }
    .see-details-btn {
      width: 100%;
    }
  }




}

.loan-account-details-container {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
}

.total-loan-amount {
  width: 50%;
  margin-left: auto;
}

.bank-wrap {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  grid-gap: 1rem;
  margin-top: 16px;
  height: 59%;

  .bank-details {
    background-color: #fff;
    border-radius: 6px;
    p {
      margin: 0;
    }
  }

  .bank-list {
    background-color: #fff;
    border-radius: 6px;
  }

  .bank-names {
    height: 60%;
    overflow: auto;
    margin-top: -10px;

    .isActive {
      color: #f54b64 !important;
    }
  }

  .bank-details-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 0.3fr;
  }


}

.bank-search-icon {
  left: 215px;
}

.search-bank-input {
  width: 220px;
}

.address-container-grid {
  display: grid;
  grid-template-columns: 1.4fr 1fr;
}

.dpd-bar {
  border: 1px solid #a7a9be;
  border-radius: 5px;
  height: 40px;
  margin-top: 20px;
}

.assert-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  &.grid-row-2-1 {
    grid-template-columns: 2fr 1fr;
  }
  &.grid-row-1-1 {
    grid-template-columns: 1fr 1fr;
  }
}

.assert-details-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.top-header {
  width: 100%;
  margin-top: 24px;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 2fr;

  .summary {
    border: 1px solid #a7a9be;
    height: 156px;
    border-radius: 5px;
  }

  .enquiry {
    border: 1px solid #a7a9be;
    height: 156px;
    border-radius: 5px;

    section {
      display: grid;
      grid-template-columns: 1.7fr 1.2fr 1fr;

      div {
        .type {
          padding: 0 12px;
          margin: 0;
          border: 1px solid #4e596f;
          color: #4e596f;
          font-size: 11px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          margin-right: 6px;
        }

        .type-primary {
          @extend .type;
          border: 1px solid #f54b64;
          max-width: 80px;
          margin-top: 6px;
        }
      }
    }
  }  

  .header-text {
    color: #242a38;
    font-size: 14px;
    font-weight: 700;
  }

}



.footer-retail-account {
  margin-top: 24px;
  margin-bottom: 24px;
  width: calc(100% - 42px);
  border: 1px solid #a7a9be;
  border-radius: 5px;
  // height: 251px;

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .header-text {
    color: #242a38;
    font-size: 14px;
    font-weight: 700;
  }

}