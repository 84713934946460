.shimmer {
    background: #e8e8e8;
    background-image: linear-gradient(to right, #ededed 8%, #e8e8e8 18%, #ededed 33%);
    background-repeat: no-repeat;
    animation: shimmering forwards infinite ease-in-out, fadein 0.02s forwards;
    width: 100%;
    background-size: 1000% 100%;
    height: 100%;
    animation-duration: 1s;
    border-radius: 4px;
  }
  
  @keyframes fadein {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes shimmering {
    from {
      background-position: top right;
    }
  
    to {
      background-position: top left;
    }
  }